<template>
  <div class="electricity">
    <p class="VCTitle">交易记录</p>
    <div v-if="isShowEmpty">
      <div
        class="ListView"
        v-for="(itemData,itemIndex) in listData"
        :key="itemIndex"
        @click="gotoDetail(itemData)"
        :style="{'margin-bottom':(listData.length-1 == itemIndex?'30px':'')}"
      >
        <p class="listTitle">
          <span v-show="itemData.type === 1">充值</span>
          <span v-show="itemData.type === 2">电费扣除</span>
        </p>
        <div class="titleView" style="margin-top:20px;">
          <p
            class="listSubtitle"
            v-for="(item,index) in titleList"
            :key="index"
            :style="{'text-align':(index==2?'right':index==1?'center':'left'),'margin-right': (index == 1? '40px':''),'width':(index==2?'200px':index==1?'70px':'')}"
          >{{titleList[index]}}</p>
        </div>
        <div class="titleView" style="margin-top:10px;">
          <p
            class="listResultTitle"
            v-for="(item,index) in titleList"
            :key="index"
            :style="{'text-align':(index==2?'right':index==1?'center':'left'),'margin-right': (index == 1? '40px':''),'width':(index==2?'200px':index==1?'70px':'')}"
          >{{index==0?itemData.amount:index==1?(statusType[itemData.status]):itemData.ctime}}</p>
        </div>
        <div class="bottomLine" v-if="!(listData.length-1 == itemIndex)"></div>
      </div>
    </div>
    <img class="emptyImage" src="../../assets/my/me_NoRecord.png" v-if="!isShowEmpty" />
    <p class="emptyTitle" v-if="!isShowEmpty">暂无任何记录～</p>
  </div>
</template>

<script>
export default {
  name: "electricity",
  data() {
    return {
      symbol: "",
      listData: [],
      statusType: ["已完成", "已完成"],
      titleList: ["金额", "状态", "时间"],
      isShowEmpty: false //是否显示空页面
    };
  },
  components: {},
  mounted() {},
  created() {
    this.symbol = this.$route.query.symbol;
    this.getRecordList();
  },
  methods: {
    //去详情页面
    gotoDetail(e) {
      this.$router.push({
        path: "/MentionRecordDetail",
        name: "MentionRecordDetail",
        params: {
          amount: e.amount,
          type: e.type,
          status: e.status,
          ctime: e.ctime
        }
      });
    },
    //提币列表
    getRecordList() {
      const params = {
        symbol: this.symbol
      };
      this.$request
        .post("/user/withdrawRecode", params)
        .then(
          res => {
            if (res.code == 0) {
              this.listData = res.data;
              if (this.listData.length > 0) {
                this.isShowEmpty = true;
              } else {
                this.isShowEmpty = false;
              }
            } else {
              this.$toast(res.msg);
              if (res.code == 1018) {
                this.$router.push("/login");
              }
            }
            // 执行成功的回调函数
          },
          error => {
            console.log(error);
            // 执行失败的回调函数
          }
        )
        .catch(() => {
          this.$toast("短信发送失败");
        });
    }
  }
};
</script>
<style scoped lang="scss">
.electricity {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.VCTitle {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  line-height: 22px;
  text-align: left;
}
.ListView {
  margin-top: 20px;
  width: 100%;
}
.listTitle {
  margin-top: 25px;
  margin-left: 20px;
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 700;
  color: #333333;
  line-height: 22px;
  text-align: left;
}
.titleView {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
}
.listSubtitle {
  width: 33.33%;
  font-size: 12px;
  font-weight: 700;
  color: #8f9fac;
  line-height: 22px;
  font-family: "HelveticaCondensedBold", "HelveticaCondensed";
}
.listResultTitle {
  width: 33.33%;
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  line-height: 22px;
  font-family: "HelveticaCondensedBold", "HelveticaCondensed";
}
.bottomLine {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  height: 1px;
  background: #f3f3f3;
}
.emptyImage {
  width: 188px;
  height: 177px;
  margin-top: 143px;
  align-self: center;
}
.emptyTitle {
  margin-top: 5px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #cccccc;
}
</style>